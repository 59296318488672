import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
		<path d="M5272 7019 c-86 -15 -201 -57 -282 -102 -98 -55 -251 -205 -309 -303
-22 -38 -41 -73 -41 -76 0 -6 144 -112 177 -132 8 -4 18 6 27 28 21 50 71 123
120 174 192 204 516 250 766 109 72 -40 197 -167 236 -240 l30 -57 85 66 c46
36 88 69 92 73 12 12 -86 154 -151 219 -159 159 -349 240 -577 247 -66 2 -144
0 -173 -6z"/>
<path d="M5335 6774 c-197 -31 -369 -155 -459 -332 -14 -28 -26 -53 -26 -55 0
-6 107 -87 124 -93 10 -4 16 4 21 25 10 45 75 141 130 188 179 158 444 146
610 -28 32 -33 68 -85 83 -118 l27 -59 63 50 c71 57 71 58 16 145 -106 166
-280 267 -475 277 -46 2 -97 2 -114 0z"/>
<path d="M5322 6349 c-122 -61 -139 -250 -30 -333 83 -63 189 -55 264 19 160
160 -30 414 -234 314z"/>
<path d="M4540 5815 c0 -247 3 -344 11 -340 5 4 8 13 5 21 -3 8 0 11 9 8 7 -3
31 10 52 28 21 18 60 51 86 72 36 29 45 41 35 47 -10 7 -9 9 5 9 9 0 19 -1 21
-2 11 -7 57 48 53 63 -2 8 1 16 8 16 7 0 11 -7 8 -15 -6 -14 16 1 56 38 9 9
33 28 54 44 20 16 37 32 37 36 0 4 -39 34 -87 66 -49 32 -90 63 -91 67 -2 4
-15 1 -27 -7 -13 -9 -31 -13 -41 -9 -14 5 -14 3 2 -15 18 -22 30 -30 20 -14
-2 4 4 13 14 20 12 7 21 8 25 2 4 -6 10 -8 15 -5 5 3 25 -11 45 -30 19 -20 40
-33 46 -29 7 4 10 1 7 -7 -3 -8 -10 -13 -15 -12 -5 1 -10 -5 -10 -13 0 -8 4
-11 9 -6 13 13 56 3 62 -14 7 -17 -6 -18 -22 -2 -8 8 -12 9 -12 1 0 -6 6 -14
13 -16 7 -3 5 -6 -5 -6 -10 -1 -18 6 -18 15 0 13 -2 14 -15 1 -8 -9 -12 -26
-9 -42 3 -17 1 -24 -6 -20 -5 3 -10 2 -10 -4 0 -6 -15 -9 -34 -8 -31 3 -33 1
-31 -24 2 -22 -3 -30 -29 -41 -17 -7 -34 -12 -38 -10 -5 1 -8 -3 -8 -9 0 -5
-5 -7 -12 -3 -7 4 -8 3 -4 -4 10 -17 -10 -15 -28 3 -13 14 -15 13 -14 -2 0
-10 -3 -27 -7 -38 l-8 -20 16 20 c16 20 16 20 14 -2 -1 -13 -7 -23 -15 -23 -7
0 -10 -5 -6 -12 5 -8 2 -9 -9 -5 -10 4 -16 2 -14 -5 1 -6 -4 -13 -11 -15 -7
-3 -10 1 -6 10 3 8 1 18 -5 22 -7 4 -8 -1 -4 -12 6 -16 4 -16 -17 2 -20 17
-23 18 -17 3 4 -10 2 -18 -3 -18 -6 0 -10 -5 -10 -11 0 -8 4 -8 15 1 16 13 35
6 34 -13 0 -9 -2 -9 -6 0 -7 18 -43 -6 -36 -24 3 -9 0 -11 -10 -7 -13 5 -16
44 -17 281 -1 253 1 276 17 288 19 14 33 11 33 -6 0 -6 -4 -7 -10 -4 -5 3 -10
1 -10 -4 0 -17 20 -13 27 4 5 14 7 14 23 0 10 -9 17 -25 15 -36 -2 -11 0 -17
6 -14 5 4 7 11 4 16 -4 5 1 9 10 9 14 0 14 2 3 13 -49 46 -121 86 -103 57 4
-6 12 -7 18 -3 7 4 6 0 -2 -10 -18 -22 -31 -13 -25 18 2 13 0 26 -5 30 -7 3
-10 -121 -11 -340z m374 -14 c3 -5 0 -11 -7 -14 -8 -3 -14 1 -14 9 0 16 13 19
21 5z m-184 -161 c0 -3 -8 -10 -18 -16 -14 -9 -18 -8 -18 5 0 9 8 17 18 17 10
0 18 -3 18 -6z"/>
<path d="M6040 5924 c-140 -129 -261 -237 -267 -240 -7 -4 -59 33 -115 81 -57
48 -123 96 -147 107 -63 29 -148 27 -205 -5 -68 -38 -705 -576 -734 -620 -31
-47 -41 -143 -22 -202 16 -50 75 -114 125 -136 54 -25 138 -24 195 2 25 11 70
42 100 68 30 26 142 120 248 210 l193 162 252 -212 c139 -116 271 -220 295
-231 56 -25 138 -25 194 0 51 23 112 86 126 133 9 29 28 1120 19 1119 -1 0
-117 -106 -257 -236z"/>
<path d="M4683 6037 c-4 -7 -17 -19 -28 -25 -19 -11 -18 -11 7 -10 15 1 25 5
22 9 -2 4 0 10 5 13 5 3 12 -1 15 -10 3 -8 10 -13 15 -10 5 3 11 -1 15 -9 3
-9 12 -12 23 -9 15 5 15 4 3 -4 -10 -8 -18 -8 -27 0 -9 7 -13 7 -13 0 0 -16
31 -23 46 -11 11 9 11 15 3 25 -6 8 -16 14 -22 14 -6 0 -21 9 -33 21 -18 17
-24 18 -31 6z"/>
<path d="M4640 5849 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4850 5840 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4805 5780 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4575 5671 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M4645 5670 c-3 -5 -3 -14 0 -20 3 -5 -3 -10 -14 -10 -25 0 -28 -15
-5 -24 22 -8 26 -8 19 3 -3 5 -1 12 4 16 5 3 9 14 8 25 -1 23 -3 24 -12 10z"/>
<path d="M4715 4570 c-44 -10 -69 -25 -103 -64 -71 -81 -46 -207 53 -263 135
-76 305 50 265 196 -24 92 -124 153 -215 131z m102 -101 c30 -29 37 -70 19
-112 -17 -41 -41 -57 -89 -57 -61 0 -100 67 -77 135 21 64 96 81 147 34z"/>
<path d="M4080 4568 c1 -2 22 -79 48 -173 l48 -170 44 0 43 0 26 95 25 95 30
-97 29 -98 41 0 41 0 44 158 c24 86 46 165 49 175 4 14 -3 17 -41 17 l-45 0
-16 -67 c-10 -38 -22 -86 -28 -108 l-10 -40 -27 105 -27 105 -37 3 c-35 3 -36
2 -47 -40 -7 -24 -20 -72 -30 -108 -20 -72 -16 -77 -49 65 l-18 80 -46 3 c-26
2 -47 2 -47 0z"/>
<path d="M5059 4543 c-14 -37 -109 -315 -109 -319 0 -2 20 -4 45 -4 42 0 45 2
53 33 l8 32 69 0 69 0 8 -32 c8 -31 11 -33 54 -33 42 0 45 1 38 23 -4 12 -30
90 -59 172 l-52 150 -57 3 c-53 3 -57 2 -67 -25z m111 -185 c0 -5 -21 -8 -46
-8 -39 0 -46 3 -41 18 2 9 13 46 23 81 l18 64 23 -74 c12 -41 23 -77 23 -81z"/>
<path d="M5480 4396 l0 -177 96 6 c73 4 105 10 135 27 103 57 103 228 1 285
-26 15 -64 23 -134 28 l-98 7 0 -176z m183 74 c31 -25 42 -69 27 -113 -12 -37
-44 -57 -90 -57 l-30 0 0 95 0 95 34 0 c18 0 45 -9 59 -20z"/>
<path d="M5870 4408 c-30 -89 -56 -167 -58 -174 -2 -8 11 -12 41 -12 43 0 45
1 51 34 l7 34 69 0 68 0 12 -35 c11 -32 14 -34 55 -33 l43 1 -59 170 c-32 94
-59 172 -59 174 0 2 -26 3 -58 3 l-57 0 -55 -162z m160 -55 c0 -2 -20 -3 -45
-3 -26 0 -45 5 -45 11 0 6 10 43 22 82 l21 71 23 -80 c13 -43 24 -80 24 -81z"/>
<path d="M6150 4530 c0 -39 1 -40 35 -40 l34 0 3 -132 3 -133 40 0 40 0 3 133
3 132 39 0 c40 0 40 0 40 40 l0 40 -120 0 -120 0 0 -40z"/>
<path d="M6495 4558 c-74 -209 -112 -329 -107 -332 4 -2 22 -3 41 -2 33 1 43
10 55 49 5 15 15 17 73 15 66 -3 67 -3 75 -35 8 -31 11 -33 53 -33 25 0 45 1
45 3 0 1 -26 79 -59 172 l-59 170 -56 3 c-39 2 -58 -1 -61 -10z m105 -200 c0
-5 -21 -8 -46 -8 -39 0 -46 3 -41 18 2 9 13 46 23 81 l18 64 23 -74 c12 -41
23 -77 23 -81z"/>
<path d="M4947 4113 c-4 -3 -7 -73 -7 -154 0 -149 2 -159 39 -159 13 0 8 29
-5 34 -9 3 -11 -1 -7 -11 4 -9 2 -14 -3 -10 -13 8 -17 258 -4 277 11 16 11 15
14 -45 3 -52 15 -78 33 -71 9 4 11 2 7 -6 -5 -8 -2 -9 10 -5 11 4 15 3 11 -4
-4 -6 -13 -8 -21 -5 -7 3 -16 1 -18 -3 -3 -5 34 -9 81 -10 48 -1 89 -3 90 -5
1 -1 4 -38 5 -82 3 -72 5 -79 25 -82 21 -3 21 -2 20 170 l-2 173 -23 3 c-23 4
-24 2 -20 -62 3 -65 2 -66 -22 -67 -37 -1 -155 2 -157 5 -1 1 -3 29 -4 64 -2
50 -5 62 -19 62 -9 0 -20 -3 -23 -7z m262 -149 c1 -93 -2 -145 -9 -149 -8 -5
-8 -11 1 -21 10 -12 9 -13 -5 -8 -18 7 -21 29 -6 39 6 4 7 12 3 18 -10 16 -10
86 0 80 4 -2 7 1 7 6 0 6 -4 11 -9 11 -5 0 -17 8 -27 17 -13 13 -18 15 -22 5
-2 -8 -8 -10 -15 -3 -7 5 -23 11 -37 13 -14 3 -2 6 25 7 28 1 57 2 65 3 16 1
16 13 6 94 -2 20 1 31 9 31 10 0 13 -34 14 -143z"/>
<path d="M5315 4110 c-12 -20 -3 -254 11 -282 18 -36 67 -58 126 -58 39 0 48
3 48 18 0 14 2 15 10 2 7 -12 15 -9 42 19 l33 34 3 138 4 139 -31 0 -32 0 3
-127 c3 -121 2 -130 -19 -154 -20 -21 -30 -25 -68 -22 -34 3 -45 0 -45 -11 0
-11 8 -13 28 -9 47 9 55 8 58 -5 1 -7 0 -8 -3 -2 -2 5 -9 10 -14 10 -6 0 -8
-4 -5 -8 4 -7 -38 -8 -77 -3 -4 1 -3 10 2 20 14 25 -12 50 -33 32 -9 -8 -16
-8 -20 -2 -4 5 3 13 15 16 13 4 18 12 15 22 -3 8 -6 66 -6 129 0 106 -1 114
-19 114 -11 0 -23 -4 -26 -10z m29 -6 c-6 -3 -8 -24 -3 -62 12 -92 10 -119 -6
-126 -12 -4 -14 10 -12 94 2 82 5 100 17 99 8 0 10 -3 4 -5z m228 -99 c2 -52
-1 -95 -6 -95 -5 0 -3 -7 4 -15 9 -11 10 -18 1 -28 -6 -8 -9 -16 -6 -18 2 -3
-4 -10 -14 -16 -26 -16 -22 8 5 38 15 17 15 21 3 17 -9 -4 -16 -1 -16 8 0 8 3
14 6 14 3 0 5 43 3 95 -2 59 0 95 7 95 5 0 11 -40 13 -95z m-222 -138 c0 -3
-6 -7 -14 -10 -9 -4 -13 2 -13 18 0 20 2 22 13 10 8 -7 14 -16 14 -18z m29
-56 c-3 -6 -10 -7 -18 -2 -7 5 -10 14 -6 21 9 14 29 -2 24 -19z m151 -15 c0
-5 -6 -3 -13 3 -8 6 -18 7 -23 3 -4 -4 -2 1 5 11 11 14 16 15 22 5 5 -7 9 -17
9 -22z"/>
<path d="M5670 3962 l0 -160 28 -11 27 -11 -27 5 c-16 3 -28 1 -28 -5 0 -5 24
-10 55 -10 30 0 55 4 55 10 0 5 7 7 15 4 13 -6 20 6 16 29 -2 10 21 8 39 -4
13 -8 12 -9 -4 -7 -10 2 -21 -3 -23 -9 -8 -22 17 -14 42 13 30 33 32 97 3 126
-21 21 -27 22 -48 8 -13 -9 -13 -12 2 -24 10 -7 15 -16 11 -19 -3 -4 -2 -7 4
-7 15 0 11 -32 -6 -49 -13 -14 -87 -31 -107 -24 -5 2 -7 28 -5 59 3 49 1 56
-13 51 -12 -5 -16 0 -16 23 0 17 5 30 11 30 5 0 7 -4 4 -10 -3 -5 1 -10 9 -10
9 0 13 -6 10 -14 -4 -12 3 -14 35 -13 37 1 46 7 42 30 0 4 4 7 9 7 6 0 10 -5
10 -10 0 -7 7 -7 20 0 11 6 18 15 15 21 -4 5 -1 9 5 9 7 0 10 7 6 15 -3 8 -1
15 5 15 6 0 7 5 3 11 -4 8 -9 8 -17 0 -16 -16 -26 -13 -23 7 2 9 -5 23 -15 30
-22 15 -25 32 -6 32 7 0 17 -10 22 -22 10 -23 35 -37 35 -20 0 5 -8 20 -18 32
-16 20 -31 24 -100 28 l-82 5 0 -161z m110 138 c0 -5 -13 -10 -29 -10 -30 0
-56 -21 -43 -35 4 -4 1 -6 -6 -4 -8 3 -13 7 -12 9 10 47 13 50 51 50 22 0 39
-4 39 -10z m37 -60 c3 -16 12 -30 19 -30 8 0 14 -5 14 -10 0 -6 -6 -10 -12 -9
-25 4 -75 -26 -71 -41 4 -13 3 -13 -6 0 -6 8 -16 15 -23 15 -8 0 -15 19 -18
53 l-6 52 48 0 c46 0 49 -2 55 -30z m-111 -22 c-3 -13 -5 -25 -5 -28 -1 -3 -5
1 -10 9 -10 16 -4 41 10 41 5 0 7 -10 5 -22z m144 -44 c0 -2 -7 -4 -15 -4 -8
0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m14 -65 c-3 -5 0 -9 7 -9 10
0 10 -3 1 -12 -8 -8 -8 -17 -1 -31 7 -13 8 -22 1 -29 -13 -13 -35 0 -25 15 15
24 11 57 -8 71 -15 10 -17 15 -7 19 16 5 40 -12 32 -24z m-177 -46 c-3 -10 -5
-4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m14 -30 c-10 -4 -8 -9 10 -19 23
-13 23 -13 3 -14 -12 0 -24 6 -27 14 -6 16 1 26 17 25 6 0 5 -3 -3 -6z m104
-33 c-3 -4 -13 -7 -23 -5 -9 1 -23 -2 -29 -8 -13 -9 -19 4 -8 15 9 9 66 7 60
-2z"/>
<path d="M5855 3840 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4967 3933 c2 -21 5 -50 4 -65 0 -16 4 -28 9 -28 6 0 10 20 10 45 0
25 -5 45 -11 45 -6 0 -8 9 -4 20 3 11 2 20 -4 20 -5 0 -7 -16 -4 -37z"/>
<path d="M4946 3792 c-13 -12 -5 -22 19 -22 14 0 25 4 25 9 0 10 -36 21 -44
13z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
